<template>
  <div>
    <v-app>
      <div v-if="!$route.meta.hiddens">
        <v-app-bar
            app
            color="primary"
            dark
            elevation="0"
            class="navbar"
            v-if="!$route.meta.hiddens"
        >
          <v-app-bar-nav-icon
              @click.stop="sidebarMenu = !sidebarMenu"
          ></v-app-bar-nav-icon>
          <v-spacer></v-spacer>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  class="ma-2"
                  outlined
                  small
                  fab
                  color="white"
                  v-bind="attrs"
                  v-on="on"
              >
                <v-icon>mdi-account</v-icon>
              </v-btn
              >
            </template>
            <v-list class="px-6">
              <v-list-item class="items-menu" v-if="userProfile.name">
                {{ userProfile.name }}
              </v-list-item
              >
              <v-list-item class="items-menu" @click="LogoutUsers()">
                Logout
              </v-list-item>
            </v-list>
          </v-menu>
        </v-app-bar>

        <v-navigation-drawer
            v-model="sidebarMenu"
            app
            floating
            :permanent="sidebarMenu"
            :mini-variant.sync="mini"
            v-if="!$route.meta.hiddens"
        >
          <v-list dense color="primary" dark>
            <v-list-item>
              <v-list-item-action>
                <v-icon @click.stop="sidebarMenu = !sidebarMenu"
                >mdi-chevron-left
                </v-icon
                >
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  <!-- <img src="@/assets/logo.png" alt="" width="60" height="auto"> -->
                  <h1 class="font-weight-regular">Vari Laos</h1>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list-item class="px-2" @click="toggleMini = !toggleMini">
            <v-list-item-avatar>
              <v-icon>mdi-account-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-content class="text-truncate" v-if="userProfile.name">
              {{ userProfile.name }}
            </v-list-item-content>
            <v-btn icon small>
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </v-list-item>
          <!-- <v-list v-if="$can('factorymanager')"> -->
          <v-list>
            <v-list-item v-if="$can(['factorymanager','superadmin'])" link :to="{ name: 'dashboard' }" exact>
              <v-list-item-icon>
                <v-icon color="primary">mdi-monitor-dashboard</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="primary--text"
                >Dashboard
                </v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-group
                :prepend-icon="'mdi-cog'"
                no-action
                v-if="$can(['factorymanager','superadmin'])"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>ຕັ້ງຄ່າ</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item link :to="{ name: 'rejectReason'}" exact>
                <v-list-item-icon>
                  <v-icon color="primary">mdi-application</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>ຂໍ້ມູນການປະຕິເສດ</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item link :to="{ name: 'ProductType'}" exact>
                <v-list-item-icon>
                  <v-icon color="primary">mdi-water</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>ຂໍ້ມູນຜະລິດຕະພັນ</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>

<!--            tracking bottle-->

<!--            <v-list-item link :to="{ name: 'tracking-bottle' }" exact>-->
<!--              <v-list-item-icon>-->
<!--                <v-icon color="primary">mdi-map-marker-circle</v-icon>-->
<!--              </v-list-item-icon>-->
<!--              <v-list-item-content>-->
<!--                <v-list-item-title class="primary&#45;&#45;text"-->
<!--                >ຕິດຕາມຕຸກນ້ຳ-->
<!--                </v-list-item-title-->
<!--                >-->
<!--              </v-list-item-content>-->
<!--            </v-list-item>-->


            <v-list-item link v-if="$can(['factorymanager','superadmin'])" :to="{ name: 'CustomerRequestOrderWater' }" exact>
              <v-list-item-icon>
                <v-icon color="primary">mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="primary--text"
                >ລູກຄ້າສັ່ງຊື້ນຳ້ຈາກເວບ
                </v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>

            <v-list-item link v-if="$can(['factorymanager','superadmin'])" :to="{ name: 'CustomerOrderWater' }" exact>
              <v-list-item-icon>
                <v-icon color="primary">mdi-water</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="primary--text"
                >ລູກຄ້າສັ່ງນຳ້ຈາກແອັບ
                </v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>

            <!--            Platform -->

            <v-list-group
                :prepend-icon="'mdi-monitor-dashboard'"
                no-action
                v-if="$can(['factorymanager','superadmin'])"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>PlatForm</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item link :to="{ name: 'News' }" exact>
                <v-list-item-icon>
                  <v-icon color="primary">mdi-card-account-details</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="primary--text"
                  >News
                  </v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>

              <v-list-item link :to="{ name: 'Banner' }" exact>
                <v-list-item-icon>
                  <v-icon color="primary">mdi-database</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="primary--text"
                  >Banner
                  </v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item link :to="{ name: 'CustomerOrder' }" exact>
                <v-list-item-icon>
                  <v-icon color="primary">mdi-cart</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="primary--text"
                  >Orders
                  </v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>

              <v-list-item link :to="{ name: 'Promotion' }" exact>
                <v-list-item-icon>
                  <v-icon color="primary">mdi-application</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="primary--text"
                  >Promotions
                  </v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item link :to="{ name: 'CustomerPromotion' }" exact>
                <v-list-item-icon>
                  <v-icon color="primary">mdi-account-group</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="primary--text"
                  >ລູກຄ້າທີ່ໄດ້ຮັບສ່ວນຫຼຸດ
                  </v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>

              <v-list-item link :to="{ name: 'CustomerUsePromotion' }" exact>
                <v-list-item-icon>
                  <v-icon color="primary">mdi-chart-bar</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="primary--text"
                  >ລູກຄ້າໃຊ້ສ່ວນຫຼຸດ
                  </v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>

              <v-list-item link :to="{ name: 'NewsPromotion' }" exact>
                <v-list-item-icon>
                  <v-icon color="primary">mdi-application</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="primary--text"
                  >ຂ່າວສານໂປຣໂມຊັ້ນ
                  </v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item link :to="{ name: 'Notification' }" exact>
                <v-list-item-icon>
                  <v-icon color="primary">mdi-bell</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="primary--text"
                  >ຂໍ້ມູນແຈ້ງເຕືອນ
                  </v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item link :to="{ name: 'CustomerRequest' }" exact>
                <v-list-item-icon>
                  <v-icon color="primary">mdi-account-group</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="primary--text"
                  >ລູກຄ້າຮ້ອງຂໍເຂົ້າມາ
                  </v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>

            </v-list-group>


            <!-- User group  -->
            <v-list-group
                :prepend-icon="'mdi-account-group'"
                no-action
                v-if="$can(['factorymanager','superadmin'])"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>ຂໍ້ມູນຜູ້ໃຊ້</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item link :to="{ name: 'UserFactory' }" exact>
                <v-list-item-icon>
                  <v-icon color="primary">mdi-account</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>User ໂຮງງານ</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item link :to="{ name: 'SortingCenterUser' }" exact>
                <v-list-item-icon>
                  <v-icon color="primary">mdi-account</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>User ສາງໂຮງງານ</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item link :to="{ name: 'Branch_User' }" exact>
                <v-list-item-icon>
                  <v-icon color="primary">mdi-card-account-details</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="primary--text"
                  >User ສາຂາ
                  </v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item link :to="{ name: 'Delivery_User' }" exact>
                <v-list-item-icon>
                  <v-icon color="primary">mdi-truck</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="primary--text"
                  >User ຈັດສົ່ງນ້ຳ
                  </v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
            <!--Route plan-->

            <v-list-group
                :prepend-icon="'mdi-truck'"
                no-action
                v-if="$can(['factorymanager','superadmin'])"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>ຈັດແຜນເສັ້ນທາງ</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item link :to="{ name: 'Plan' }" exact>
                <v-list-item-icon>
                  <v-icon color="primary">mdi-truck</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>ແຜນເສັ້ນທາງ</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item link :to="{ name: 'Calendar' }" exact>
                <v-list-item-icon>
                  <v-icon color="primary">mdi-card-account-details</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="primary--text"
                  >ແຜນເວລາຈັດສົ່ງ
                  </v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item link :to="{ name: 'PlanMapView' }" exact>
                <v-list-item-icon>
                  <v-icon color="primary">mdi-map</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>ເສັ້ນທາງຕາມແຜນທີ່</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>

            <v-list-group
                :prepend-icon="'mdi-database'"
                no-action
                v-if="$can(['factorymanager','superadmin'])"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>ຂໍ້ມູນລະບົບ</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item link :to="{ name: 'Factory' }" exact>
                <v-list-item-icon>
                  <v-icon color="primary">mdi-hospital-building</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="primary--text"
                  >ຂໍ້ມູນໂຮງງານ
                  </v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item link :to="{ name: 'SortingCenter' }" exact>
                <v-list-item-icon>
                  <v-icon color="primary">mdi-hospital-building</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="primary--text"
                  >ສາງໂຮງງານ
                  </v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item link :to="{ name: 'Branch' }" exact>
                <v-list-item-icon>
                  <v-icon color="primary">mdi-hospital-building</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="primary--text"
                  >ຂໍ້ມູນສາຂາ
                  </v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item link :to="{ name: 'Customer' }" exact>
                <v-list-item-icon>
                  <v-icon color="primary"
                  >mdi-account-supervisor-outline
                  </v-icon
                  >
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="primary--text"
                  >ຂໍ້ມູນລູກຄ້າ
                  </v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <!-- v-if="$can(['factorymanager', 'branchmanager'])"
              link
              :to="{ name: 'Village' }"
              exact
            > -->

              <v-list-item link :to="{ name: 'Truck' }" exact>
                <v-list-item-icon>
                  <v-icon color="primary">mdi-truck</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="primary--text"
                  >ລົດຂົນສົ່ງ
                  </v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item link :to="{ name: 'Package' }" exact>
                <v-list-item-icon>
                  <v-icon color="primary">mdi-package</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="primary--text"
                  >Package
                  </v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item link :to="{ name: 'Subscription' }" exact>
                <v-list-item-icon>
                  <v-icon color="primary">mdi-account-cash</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="primary--text"
                  >Subscription
                  </v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                  v-if="$can(['factorymanager','superadmin'])"
                  link
                  :to="{ name: 'Village' }"
                  exact
              >
                <v-list-item-icon>
                  <v-icon color="primary">mdi-home-group</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="primary--text"
                  >ຂໍ້ມູນບ້ານ
                  </v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                  v-if="$can(['factorymanager','superadmin'])"
                  link
                  :to="{ name: 'department' }"
                  exact
              >
                <v-list-item-icon>
                  <v-icon color="primary">mdi-dip-switch</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="primary--text"
                  >ຂໍ້ມູນພະແນກ
                  </v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list-group>

            <v-list-group
                :prepend-icon="'mdi-water'"
                no-action
                v-if="$can(['factorymanager', 'factoryuser','superadmin'])"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>ຈັດການຕຸກນ້ຳ</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item link :to="{ name: 'Water' }" exact>
                <v-list-item-icon>
                  <v-icon color="primary">mdi-cup-water</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>ຂໍ້ມູນຕຸກນ້ຳ</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item link :to="{ name: 'Bottle-Broken' }" exact>
                <v-list-item-icon>
                  <v-icon color="primary">mdi-cup-off</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>ຕຸກນ້ຳເສຍຫາຍ</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
            <!-- -->

            <!-- <v-list-group :prepend-icon="'mdi-eight-track'" no-action v-if="$can(['factorymanager','factoryuser'])">
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>ກວດສອບຕຸກນ້ຳ</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item link :to="{ name: 'WaterTracking' }" exact>
                <v-list-item-icon>
                  <v-icon color="primary">mdi-barcode-scan</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>ຕິດຕາມຕຸກນ້ຳ</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group> -->

            <!--
            <v-list-item link :to="{ name: 'Factory' }" exact>
              <v-list-item-icon>
                <v-icon color="primary">mdi-factory</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="primary--text"
                  >Factory</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            -->
            <!--
            <v-list-item link :to="{ name: 'Role' }" exact>
              <v-list-item-icon>
                <v-icon color="primary">mdi-account-search-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="primary--text"
                  >User Role</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            -->
            <v-list-group
                :prepend-icon="'mdi-chart-bar'"
                no-action
                v-if="$can(['financemanager','superadmin', 'financeuser', 'factorymanager'])"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>ລາຍງານ</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                  link
                  :to="{ name: 'Report-Bottle' }"
                  exact
                  v-if="$can(['financemanager','superadmin', 'financeuser', 'factorymanager'])"
              >
                <v-list-item-icon>
                  <v-icon color="primary">mdi-chart-bar</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="primary--text"
                  >ຍອດຜະລິດ
                  </v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                  link
                  :to="{ name: 'Finance' }"
                  exact
                  v-if="$can(['financemanager','superadmin', 'financeuser', 'factorymanager'])"
              >
                <v-list-item-icon>
                  <v-icon color="primary">mdi-finance</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="primary--text"
                  >ຍອດຂາຍ
                  </v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                  link
                  :to="{ name: 'ReportBranchInvoice' }"
                  exact
                  v-if="$can(['financemanager','superadmin', 'factorymanager'])"
              >
                <v-list-item-icon>
                  <v-icon color="primary">mdi-finance</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="primary--text"
                  >ຍອດຈັດສົ່ງໃຫ້ແຟຣນຊາຍ
                  </v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                  link
                  :to="{ name: 'Return-Money-Customer' }"
                  exact
                  v-if="$can(['financemanager','superadmin', 'financeuser', 'factorymanager'])"
              >
                <v-list-item-icon>
                  <v-icon color="primary">mdi-account-cash-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="primary--text"
                  >ຍອດເງິນມັດຈຳ
                  </v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>

<!--              <v-list-item-->
<!--                  link-->
<!--                  :to="{ name: 'Issue-Bottle' }"-->
<!--                  exact-->
<!--                  v-if="$can(['financemanager','superadmin', 'financeuser', 'factorymanager'])"-->
<!--              >-->
<!--                <v-list-item-icon>-->
<!--                  <v-icon color="primary">mdi-alert</v-icon>-->
<!--                </v-list-item-icon>-->
<!--                <v-list-item-content>-->
<!--                  <v-list-item-title class="primary&#45;&#45;text"-->
<!--                  >ຕຸກນ້ຳມີບັນຫາ-->
<!--                  </v-list-item-title-->
<!--                  >-->
<!--                </v-list-item-content>-->
<!--              </v-list-item>-->

            </v-list-group>


            <!--            <v-list-item-->
            <!--              link-->
            <!--              :to="{ name: 'Report-Customer' }"-->
            <!--              exact-->
            <!--              v-if="$can(['financemanager', 'financeuser', 'factorymanager'])"-->
            <!--            >-->
            <!--              <v-list-item-icon>-->
            <!--                <v-icon color="primary">mdi-finance</v-icon>-->
            <!--              </v-list-item-icon>-->
            <!--              <v-list-item-content>-->
            <!--                <v-list-item-title class="primary&#45;&#45;text"-->
            <!--                  >ລາຍງານລູກຄ້າສັງຊື້ນ້ຳ</v-list-item-title-->
            <!--                >-->
            <!--              </v-list-item-content>-->
            <!--            </v-list-item>-->

            <!--            <v-list-item-->
            <!--              link-->
            <!--              :to="{ name: 'None-Active-Customer' }"-->
            <!--              exact-->
            <!--              v-if="$can(['financemanager', 'financeuser', 'factorymanager'])"-->
            <!--            >-->
            <!--              <v-list-item-icon>-->
            <!--                <v-icon color="primary">mdi-cash-remove</v-icon>-->
            <!--              </v-list-item-icon>-->
            <!--              <v-list-item-content>-->
            <!--                <v-list-item-title class="primary&#45;&#45;text"-->
            <!--                  >ລາຍງານລູກຄ້າອາດຈະບໍ່ຊື້ນ້ຳ</v-list-item-title-->
            <!--                >-->
            <!--              </v-list-item-content>-->
            <!--            </v-list-item>-->
            <!--            -->
            <!--
            <v-list-item
              link
              :to="{ name: 'Customer-Close' }"
              exact
              v-if="$can(['financemanager', 'financeuser', 'factorymanager'])"
            >
              <v-list-item-icon>
                <v-icon color="primary">mdi-account-remove</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="primary--text"
                  >ລາຍງານລູກຄ້າຍົກເລີກຊື້ນ້ຳ</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            -->


<!--            Branch User-->
            <v-list-item v-if="$can(['branchuser','branchmanager',])" link :to="{ name: 'BottleReport' }" exact>
              <v-list-item-icon>
                <v-icon color="primary">mdi-monitor-dashboard</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="primary--text"
                >ລາຍງານຍອດຕຸກນ້ຳສາຂາ
                </v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="$can(['branchuser','branchmanager','driverandbranch'])" link :to="{ name: 'SaleReport' }" exact>
              <v-list-item-icon>
                <v-icon color="primary">mdi-monitor-dashboard</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="primary--text"
                >ລາຍງານຍອດຂາຍສາຂາ
                </v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="$can(['branchuser','branchmanager','driverandbranch'])" link :to="{ name: 'RoutePlan' }" exact>
              <v-list-item-icon>
                <v-icon color="primary">mdi-monitor-dashboard</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="primary--text"
                >Route Plan
                </v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>

          </v-list>

          <v-list-group
              :prepend-icon="'mdi-warehouse'"
              no-action
              v-if="
              $can([
                  'superadmin',
                'factorymanager',
                'warehousemanager',
                'warehouseuser',
                'financemanager',
              ])
            "
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>ສາງສິນຄ້າ</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item link :to="{ name: 'Warehouse' }" exact>
              <v-list-item-icon>
                <v-icon color="primary">mdi-dip-switch</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>ຂໍ້ມູນສິນຄ້າ</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item link :to="{ name: 'purchase' }" exact>
              <v-list-item-icon>
                <v-icon color="primary">mdi-home-import-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>ຈັດຊື້ສິນຄ້າເຂົ້າ</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item link :to="{ name: 'Export-Warehouse' }" exact>
              <v-list-item-icon>
                <v-icon color="primary">mdi-home-export-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>ເບີກຈ່າຍສິນຄ້າອອກ</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item link :to="{ name: 'Return-Warehouse' }" exact>
              <v-list-item-icon>
                <v-icon color="primary">mdi-keyboard-return</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>ຮັບສິນຄ້າເຂົາສາງຄືນ</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item link :to="{ name: 'supplier' }" exact>
              <v-list-item-icon>
                <v-icon color="primary">mdi-account-supervisor-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>ຜູ້ສະໜອງສິນຄ້າ</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item link :to="{ name: 'category' }" exact>
              <v-list-item-icon>
                <v-icon color="primary">mdi-tag-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>ໝວດໝູ່ສິນຄ້າ</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item link :to="{ name: 'Warehouse-Report' }" exact>
              <v-list-item-icon>
                <v-icon color="primary">mdi-finance</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>ລາຍງານຍອດສາງ</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-spacer></v-spacer>
          </v-list-group>
        </v-navigation-drawer>
      </div>
      <v-content>
        <v-container class="px-4 py-0 fill-height" fluid fill-height>
          <v-row class="fill-height">
            <v-col>
              <transition name="fade">
                <router-view></router-view>
              </transition>
            </v-col>
          </v-row>
        </v-container>
        <Snackbar></Snackbar>
        <Loading></Loading>
      </v-content>
      <!--
    <v-footer class="py-3">
      <span class="ml-auto overline">Vari &copy;2020</span>
    </v-footer>
    -->
    </v-app>
  </div>
</template>

<script>
import Snackbar from "../../components/Toasts/Snackbar";
import Loading from "../../components/Modals/Loading";
import {mapGetters, mapActions} from "vuex";

export default {
  name: "App",
  components: {
    Snackbar,
    Loading
  },
  data: () => ({
    sidebarMenu: true,
    toggleMini: false,
    userProfile: {},
    checkToken: "",

    // items: [
    //   { title: "Home", href: "/", icon: "mdi-home-outline" },
    //   {
    //     title: "User Role",
    //     href: "/user-role",
    //     icon: "mdi-account-search-outline",
    //   },
    //   {
    //     title: "ຂໍ້ມູນຜູ້ໃຊ້",
    //     href: "/user",
    //     icon: "mdi-shield-account",
    //   },
    //   { title: "Components", href: "/comp", icon: "mdi-palette-swatch" },
    //   { title: "Orders", href: "/orders", icon: "mdi-bus-clock" },
    //   { title: "Settings", href: "/settings", icon: "mdi-shield-account" },
    // ],

    toast: {
      value: true,
      color: "success",
      msg: "Success",
    },
  }),
  methods: {
    ...mapActions({
      LogoutUser: "User/destroyToken",
    }),
    LogoutUsers() {
      this.LogoutUser();
    },
  },

  computed: {
    user() {
      return this.$store.getters["User/getUserType"];
    },
    profile() {
      return this.$store.getters["User/getUserProfile"];
    },

    mini() {
      return this.$vuetify.breakpoint.smAndDown || this.toggleMini;
    },
    ...mapGetters({
      userRole: "User/getUserType",
    }),
  },
  created() {
    this.userProfile = JSON.parse(window.localStorage.getItem("User"));
    //Check Token Expire and Relogin
    // this.$axios
    //   .get("report/data/on/dashboard")
    //   .then()
    //   .catch((error) => {
    //     if (error.response.status == 401) {
    //       localStorage.removeItem("access_token");
    //       this.$router.push({ name: "Login" });
    //     }
    //   });
  },
};
</script>

<style lang="scss" scoped>
@import "public/scss/main.scss";

.fade-enter-active,
.fade-leave-active {
  transition-property: opacity;
  transition-duration: 0.25s;
}

.v-application--is-ltr
.v-list-group--no-action
> .v-list-group__items
> .v-list-item {
  padding-left: 30px;
}

.fade-enter-active {
  transition-delay: 0.25s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.theme--light.v-application ::-webkit-scrollbar {
  width: 13px;
}

.theme--light.v-application ::-webkit-scrollbar-track {
  background: #e6e6e6;
  border-left: 1px solid #dadada;
}

.theme--light.v-application ::-webkit-scrollbar-thumb {
  background: #b0b0b0;
  border: solid 3px #e6e6e6;
  border-radius: 7px;
}

.theme--light.v-application ::-webkit-scrollbar-thumb:hover {
  background: black;
}

.theme--dark.v-application ::-webkit-scrollbar {
  width: 13px;
}

.theme--dark.v-application ::-webkit-scrollbar-track {
  background: #202020;
  border-left: 1px solid #2c2c2c;
}

.theme--dark.v-application ::-webkit-scrollbar-thumb {
  background: #3e3e3e;
  border: solid 3px #202020;
  border-radius: 7px;
}

.theme--dark.v-application ::-webkit-scrollbar-thumb:hover {
  background-color: $second-color !important;
}

.theme--light.v-application {
  background-color: $second-color !important;
}

.v-application .primary {
  background-color: $primary-color !important;
}

.v-application .primary--text {
  color: $black-color !important;
}
</style>
